import { FC, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import styled from "styled-components";
import { UserType } from "../../types";
import { Avatar, Paper } from "../../components";
import { ChatUserSearch } from "../chat";


const StyledChatButtonGroup = styled(ButtonGroup)`
  width: 100%;
  margin-top: 50px;
`;

const StyledChatButton = styled(Button)<{
  key: React.Key | null | undefined;
}>`
  background: transparent;
  backdrop-filter: blur(5px);
  border: 0;
  border-radius: 8px 0 0 8px;
  display: flex;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

const StyledChatButtonText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledChatHalfText = styled.div`
  padding-left: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledChatButtonTime = styled.div`
  font-size: 0.8rem;
`;


const ChatList: FC<{
  chatUsers: UserType[];
}> = ({ chatUsers }) => {
  const [search, setSearch] = useState("");

  const handleSearchChange = (search: string) => {
    setSearch(search);
  };
  
  return (
    <Paper
      variant="p-0"
      style={{
        height: "100%",
        overflowY: "auto",
      }}
    >
      <ChatUserSearch
        searchValue={search}
        onSearchChange={handleSearchChange}
      />
      <StyledChatButtonGroup vertical>
        {
          chatUsers?.map((chatUser: any, index: number) => (
            <StyledChatButton key={`chatUser-${index}`}>
                <Avatar
                  email={chatUser.email}
                  height={50}
                  size={100}
                />
                <StyledChatButtonText>
                  <StyledChatHalfText>
                    <div>
                      {`${chatUser.lastName} ${chatUser.firstName}`}
                    </div>
                    
                    <StyledChatButtonTime
                      className="text-muted"
                    >
                      Mon
                      {/* // TODO: replace with time */}
                    </StyledChatButtonTime>
                  </StyledChatHalfText>

                  <StyledChatHalfText>
                    <div className="text-muted">
                      Last message in chat
                      {/* // TODO: replace with last message */}
                    </div>
                  </StyledChatHalfText>
                
                  {/* // TODO: add unread messages count */}
                  {/* // TODO: add pin chat */}
                </StyledChatButtonText>
            </StyledChatButton>
          ))
        }
      </StyledChatButtonGroup>
    </Paper>
  );
};

export default ChatList;
