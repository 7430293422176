import moment from "moment";
import { FC } from "react";
import styled from "styled-components";
import { Avatar } from "..";
import { MessageType, UserType } from "../../types";
import { getUser } from "../../utils";
import ChatMessageInfo from "./ChatMessageInfo";
import { Timestamp } from "firebase/firestore";

const MessagesBox = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  margin: 50px 5px;
  overflow-y: auto;
`;

const MessageContainer = styled.div<{
  isOwn: boolean;
  isFirst?: boolean;
  isFirstThisDay?: boolean;
}>`
  display: flex;
  flex-direction: column;
  ${({ isOwn }) => `
    align-items: ${isOwn ? "flex-end" : "flex-start"};
    border-${
      isOwn ? "right" : "left"
    }: 2px solid ${
      isOwn ? "rgba(84, 189, 249, 0.7)" : "rgba(98,98,98, 0.4)"
    };
    padding-${isOwn ? "right" : "left"}: 10px;
  `}
  ${({
    isFirst, isFirstThisDay
  }) => isFirst && !isFirstThisDay && "margin-top: 20px;"}
`;

const Message = styled.div<{
  isOwn: boolean;
}>`
  margin: 5px 0 5px 0;
  display: inline-block;
  max-width: 70%;
  color: white;
  border-radius: 8px;
`;

const DayDivider = styled.div<{
  isFirst: boolean;
}>`
  width: 100%;
  color: rgba(255,255,255,.6);
  font-size: 0.8rem;
  text-align: center;
  margin-top: ${({ isFirst }) => (isFirst ? "0" : "30px")};
`;

const ChatMessagesBox: FC<{
  chatMessages: (MessageType & {
    isFirst?: boolean;
    isFirstThisDay?: boolean;
  })[];
  receiver: UserType;
}> = ({ chatMessages, receiver }) => {
  const user = getUser();

  const calcIsOwn = (message: MessageType) => {
    return message.senderId === user?.id;
  };

  const getFormattedTimestamp = (timestamp: Timestamp) => {
    return moment(
      new Date(
        timestamp
          .toDate()
      )
    ).calendar();
    // TODO: remake this part according to the idea
  };


  return (
    <MessagesBox
      ref={(ref) => {
        if (ref) {
          ref.scrollTop = ref.scrollHeight;
        }
      }}
    >
        {chatMessages?.map((message, index) => (
          <div
            key={`message-${message.id}`}
          >
            {
              message.isFirstThisDay && (
                <DayDivider
                  isFirst={index === 0}
                >
                  {getFormattedTimestamp(message.createdAt)}
                </DayDivider>
              )
            }
            <MessageContainer
              isOwn={calcIsOwn(message)}
              isFirst={message.isFirst}
              isFirstThisDay={message.isFirstThisDay}
            >
              {
                message.isFirst && (
                  <ChatMessageInfo
                    message={message}
                    receiver={receiver}
                  />
                )
              }

              <Message
                isOwn={calcIsOwn(message)}
              >
                {message.content.text}
                {/* // TODO: refactor to use other message types */}
              </Message>
            </MessageContainer>
          </div>
        ))}
    </MessagesBox>
  );
};

export default ChatMessagesBox;
