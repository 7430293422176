import { DocumentData, DocumentSnapshot, collection, doc, documentId, getDocs, query, where } from "firebase/firestore";
import { FC, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { PrivateRoute, Wrapper } from "../components";
import { ChatBlock, ChatList } from "../components/chat";
import { firestore } from "../firebase-config";
import { useFDoc } from "../hooks";
import { getUser } from "../utils";
import { UserType } from "../types";

const StyledChatRow = styled(Row)`
  height: 100%;
`;

const StyledChatsColumn = styled(Col)`
  padding-right: 0;
  height: 100%;
`;

const StyledWrapper = styled(Wrapper)`
  height: calc(100vh - 56px);
`;


const ChatPage: FC = (props) => {
  const user = getUser();
  
  const [chatUsers, setChatUsers] = useState<any | any[]>(undefined);

  // get user profile
  const profile = useFDoc(
    doc(firestore, "users", `${user.id}`),
    async (userDocument: DocumentSnapshot<DocumentData>) => {

      // get group users collection
      setChatUsers(
        await getDocs(
          query(collection(firestore, "users"), where(documentId(), "in", userDocument.data()?.chats))
        ).then((snapshot) => {
          return snapshot.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
        })
      );
      //
    }
  );
  
  return (
    <PrivateRoute
      showFooter={false}
    >
      <StyledWrapper
        className="pt-3 pb-3"
        showBackground
      >
        <StyledChatRow>
          <StyledChatsColumn xs={4}>
            <ChatList chatUsers={chatUsers} />
          </StyledChatsColumn>
          <StyledChatsColumn xs={8}>
            <ChatBlock
              receiver={user as UserType}
            />
          </StyledChatsColumn>
        </StyledChatRow>
      </StyledWrapper>
    </PrivateRoute>
  );
};

export default ChatPage;
