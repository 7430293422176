import { FC } from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  variant?: string;
  style?: any;
  children: any;
}

const StyledAlert = styled(Alert)`
  background: rgba(78,78,78, 0.4);
  backdrop-filter: blur(5px);
  border: 2px solid rgba(98,98,98, 0.4);
  border-radius: 8px;
`;

export const Paper: FC<Props> = ({ variant, style, children }) => {
  return (
    <StyledAlert
      variant={`dark ${variant}`}
      style={style}
    >
      {children}
    </StyledAlert>
  );
};
