import { Timestamp } from "firebase/firestore";

export type UserType = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  chats?: string[];
  groups?: string[];
};

export enum MESSAGES_TYPES {
  TEXT = "TEXT",
  // IMAGE = "IMAGE",
  // VIDEO = "VIDEO",
  // AUDIO = "AUDIO",
  // FILE = "FILE",
  // MARK = "MARK",
};

export type TextMessage = {
  text: string;
};

export type MessageType = {
  id: string;
  type: MESSAGES_TYPES;
  content: TextMessage;
  senderId: string;
  receiverId: string;
  createdAt: Timestamp;
};
