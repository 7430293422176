import { FC } from "react";
import { MdClear } from "react-icons/md";
import styled from "styled-components";

const InputBox = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Input = styled.input`
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 0 0 0 8px;
  padding-left: 20px;
  font-size: 16px;
  width: calc(100% - 50px);
  height: 50px;

  ::placeholder {
    color: rgba(255,255,255,.6);
  }

  &:focus {
    outline: none;
    box-shadow: none;

    ::placeholder {
      color: rgba(255,255,255,.2);
    }
  }
`;

const ClearButton = styled.button<{
  disabled: boolean;
}>`
  width: 50px;
  height: 50px;
  color: rgba(255,255,255,1);
  border: none;
  border-radius: 0 0 8px 0;
  background-color: transparent;

  ${({ disabled }) => `
    ${disabled && `
      cursor: text;
      color: transparent;
    `}
  `}

  &:hover {
    ${({ disabled }) => `
      color: ${disabled ? `
        transparent
      ` : `
        rgba(255,255,255,.6)
      `};
    `}
  }
`;

const ChatUserSearch: FC<{
  searchValue: string;
  onSearchChange: (search: string) => void;
}> = ({
  searchValue,
  onSearchChange
}) => {
  // handle clear search
  const handleClearSearch = () => {
    onSearchChange("");
  };
  //

  //handle search value changed
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.target.value);
  };
  //

  return (
    <InputBox>
      <Input
        type="text"
        placeholder="Search"
        value={searchValue}
        onChange={handleSearchChange}
      />
      <ClearButton
        disabled={searchValue === ""}
        onClick={handleClearSearch}
      >
        <MdClear />
      </ClearButton>
    </InputBox>
  );
};

export default ChatUserSearch;
