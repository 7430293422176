import { FC } from "react";
import { IoSend } from "react-icons/io5";
import styled from "styled-components";

const InputBox = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const Input = styled.input`
  background-color: rgba(33, 37, 41, 1);
  color: #fff;
  border: none;
  border-radius: 0 0 0 8px;
  padding-left: 20px;
  font-size: 16px;
  width: calc(100% - 50px);
  height: 50px;

  ::placeholder {
    color: rgba(255,255,255,.6);
  }

  &:focus {
    outline: none;
    box-shadow: none;

    ::placeholder {
      color: rgba(255,255,255,.2);
    }
  }
`;

const SendButton = styled.button<{
  disabled: boolean;
}>`
  width: 50px;
  height: 50px;
  color: rgba(255,255,255,1);
  border: none;
  border-radius: 0 0 8px 0;
  background-color: rgba(33, 37, 41, 1);

  ${({ disabled }) => `
    ${disabled && `
      cursor: text;
      color: transparent;
    `}
  `}

  &:hover {
    ${({ disabled }) => `
      color: ${disabled ? `
        transparent
      ` : `
        rgba(255,255,255,.6)
      `};
    `}
  }
`;

const ChatInputBox: FC<{
  inputValue: string;
  handleSendMessage: () => void;
  handleMessageChanged: (event:  any) => void;
}> = ({ inputValue, handleSendMessage, handleMessageChanged }) => {
  // call handleSendMessage on 'enter' key down
  const handleEnterKeyDown= (event: any) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  }
  //

  return (
    <InputBox>
      <Input
        type="text"
        placeholder="Input your message..."
        value={inputValue}
        onChange={handleMessageChanged}
        onKeyDown={handleEnterKeyDown}
      />
      <SendButton
        disabled={inputValue === ""}
        onClick={handleSendMessage}
      >
        <IoSend />
      </SendButton>
    </InputBox>
  );
};

export default ChatInputBox;
