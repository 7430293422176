import React, { FC, useContext } from "react";
import { Navigate } from "react-router-dom";
import { Footer, Loader, NavBar } from "../components";
import * as ROUTES from "../constants/routes";
import { FirebaseContext } from "../context/firebase";

type PrivateRouteProps = {
  children: React.ReactNode;
  showNavBar?: boolean;
  showFooter?: boolean;
};

export const PrivateRoute: FC<PrivateRouteProps> = ({
  showNavBar = true,
  showFooter = true,
  children
}) => {
  const { user, initializing } = useContext(FirebaseContext);
  return (
    <>
      {
        showNavBar && (
          <NavBar />
        )
      }

      {user ? (
        children
      ) : initializing ? (
        <Loader />
      ) : (
        <Navigate to={ROUTES.SIGN_IN} />
      )}

      {
        showFooter && (
          <Footer />
        )
      }
    </>
  );
};
