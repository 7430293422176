import { DocumentData, DocumentSnapshot, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export default function useFDoc(
  reference: any,
  onNext?: (snapshot: DocumentSnapshot<DocumentData>) => void
  ) {
  const [docs, setDocs] = useState<DocumentData | null>([]);

  useEffect(
    () =>
      onSnapshot(reference, (snapshot: any) => {
        onNext?.(snapshot);

        setDocs(snapshot.data());
      }),
    // eslint-disable-next-line
    []
  );

  return docs;
}
