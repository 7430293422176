import { Timestamp, addDoc, collection, query, where } from "firebase/firestore";
import { FC, useState } from "react";
import { Paper } from "../../components";
import { firestore } from "../../firebase-config";
import { useFQuery } from "../../hooks";
import { MESSAGES_TYPES, MessageType, UserType } from "../../types";
import { getUser } from "../../utils";
import ChatHeaderBox from "./ChatHeaderBox";
import ChatInputBox from "./ChatInputBox";
import ChatMessagesBox from "./ChatMessagesBox";


const ChatBlock: FC<{
  receiver: UserType;
}> = ({ receiver }) => {
  const user = getUser();

  const [inputValue, setInputValue] = useState("");

  // send message handler
  const handleSendMessage = async () => {
    try {
      const message = inputValue;

      setInputValue("");

      await addDoc(collection(firestore, "messages"), {
        type: MESSAGES_TYPES.TEXT,
        content: {
          text: message,
        },
        senderId: user?.id,
        receiverId: receiver?.id,
        createdAt: Timestamp.fromDate(
          new Date()
        ),
      });
    } catch (err) {
      console.error(err);
    }
  };
  //

  // get senders chat messages
  const sendersChatMessages = useFQuery(
    query(collection(firestore, "messages"),
      where("senderId", "==", user?.id),
      where("receiverId", "==", receiver?.id)
    )
  );
  //

  // get receivers chat messages
  const receiversChatMessages = useFQuery(
    query(collection(firestore, "messages"),
      where("senderId", "==", receiver?.id),
      where("receiverId", "==", user?.id)
    )
  );
  //


  // merge senders and receivers chat messages and sort them by createdAt
  const chatMessages: MessageType[] = [];

  if (sendersChatMessages && receiversChatMessages) {

    chatMessages.push(...sendersChatMessages as MessageType[]);
    if (user?.id !== receiver?.id) {
      chatMessages.push(...receiversChatMessages as MessageType[]);
    }

    chatMessages.sort(
      (messageA, messageB) => messageA.createdAt.toMillis() - messageB.createdAt.toMillis()
    );
  }
  //
  
  // format messages to show first message of the day
  // and first message of the sender in last 20 minutes
  let lastFirstMessageIndex = 0;
  const formattedMessages = chatMessages.map((message, index, arr) => {
    if (
      index === 0
      || message.senderId !== arr[index - 1].senderId
      || message.createdAt.toMillis() - arr[lastFirstMessageIndex].createdAt.toMillis() > 1000 * 60 * 20
    ) {
      lastFirstMessageIndex = index;

      // if the day of the previous message is different from the current message
      if (
        index === 0
        || message.createdAt.toDate().getDate() !== arr[index - 1].createdAt.toDate().getDate()
      ) {
        return {
          ...message,
          isFirst: true,
          isFirstThisDay: true,
        };
      } else {
        return {
          ...message,
          isFirst: true,
        };
      }
      //
    }
    
    return message;
  });
  //

  return (
    <Paper
      style={{
        height: "100%"
      }}
    >
      <ChatHeaderBox receiver={receiver} />

      <ChatMessagesBox chatMessages={formattedMessages} receiver={receiver} />

      <ChatInputBox
        inputValue={inputValue}
        handleSendMessage={handleSendMessage}
        handleMessageChanged={
          (event) => setInputValue(event.target.value)
        }
      />
    </Paper>
  );
};

export default ChatBlock;
