import { FC } from "react";
import { IoSend } from "react-icons/io5";
import styled from "styled-components";
import { MessageType, UserType } from "../../types";
import { getUser } from "../../utils";
import moment from "moment";
import { Avatar } from "../Avatar";
import { Timestamp } from "firebase/firestore";

const MessageInfo = styled.div`
  display: flex;
`;

const MessageDate = styled.div<{
  isOwn: boolean;
}>`
  font-size: 0.8rem;
  color: rgba(255,255,255,.6);
  display: flex;
  align-items: center;
  margin-${({ isOwn }) => (isOwn ? "right" : "left")}: 10px;
`;

const ChatMessageInfo: FC<{
  message: MessageType;
  receiver: UserType;
}> = ({ message, receiver }) => {
  const user = getUser();

  const isOwn = message.senderId === user?.id;

  const getFormattedTimestamp = (timestamp: Timestamp, format?: string) => {
    return moment(timestamp.toDate()).format(format);
  };

  const date = (
    <MessageDate isOwn={isOwn}>
      {getFormattedTimestamp(message.createdAt, 'LT')}
    </MessageDate>
  );

  const avatar = (
    <Avatar
      email={
        isOwn
          ? user?.email
          : receiver.email
      }
      height={30}
      size={100}
    />
  );

  const leftContent = isOwn ? date : avatar;
  const rightContent = isOwn ? avatar : date;

  return (
    <MessageInfo>
      {leftContent}
      {rightContent}
    </MessageInfo>
  );
};

export default ChatMessageInfo;
