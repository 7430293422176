import { FC } from "react";
import styled from "styled-components";
import { Avatar } from "../../components";
import { UserType } from "../../types";

const HeaderBox = styled.div`
  width: 100%;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px 8px 0 0;
  background-color: rgba(33, 37, 41, 1);
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

const ChatName = styled.div`
  font-size: 1.1rem;
  color: #fff;
  margin-left: 20px;
`;

const ChatHeaderBox: FC<{
  receiver: UserType;
}> = ({ receiver }) => {
  return (
    <HeaderBox>
      <Avatar
        email={receiver?.email}
        height={40}
        size={100}
      />
      <ChatName>
        {`${receiver?.lastName} ${receiver?.firstName}`}
      </ChatName>
    </HeaderBox>
  );
};

export default ChatHeaderBox;
